import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, mergeMap, of, withLatestFrom } from 'rxjs';

import { SessionStorage } from '@app/utilities';

import { CropDevelopmentStateContainer } from './crop-development-state.model';
import {
    loadCharacteristics,
    loadCharacteristicsIfNeeded,
    loadCharacteristicsSuccess,
    setCharacteristics,
} from './crop-development.actions';

@Injectable({
    providedIn: 'root',
})
export class CropDevelopmentEffects extends SessionStorage {
    private readonly _actions$ = inject(Actions);
    private readonly _store: Store<CropDevelopmentStateContainer> = inject(Store);

    protected readonly SESSION_STORAGE_KEY = 'cropDevelopment';

    constructor() {
        super();
    }

    /**
     * This effect checks if the characteristics are already in the store.
     * If not: then it will fire an action to really load the characteristics.
     */
    public loadCharacteristicsIfNeeded$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadCharacteristicsIfNeeded),
            withLatestFrom(this._store),
            mergeMap(([_, state]) => {
                const storedData = this.getSessionStorage();
                if (!state.cropDevelopment.characteristicDefinitions) {
                    // We don't have the characteristics, so request it
                    return of(
                        storedData
                            ? setCharacteristics({
                                  characteristicDefinitions: storedData.characteristicDefinitions,
                              })
                            : loadCharacteristics(),
                    );
                } else {
                    // We have the characteristics, so indicate it is ready.
                    return EMPTY;
                }
            }),
        );
    });

    /**
     * This effect will convert the received characteristics into a simple array of strings
     * and will fire an action to save this result in the store.
     */
    public loadCharacteristicsSuccess$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadCharacteristicsSuccess),
            mergeMap((action) => {
                const storedData = this.getSessionStorage() || {};
                this.setSessionStorage({
                    ...storedData,
                    characteristicDefinitions: action.characteristicDefinitions,
                });

                return of(
                    setCharacteristics({ characteristicDefinitions: action.characteristicDefinitions }),
                );
            }),
        );
    });
}
