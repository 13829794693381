import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { nameof } from '@app/utilities';

import { CharacteristicsApiService } from '../characteristics.api.service';
import { CropDevelopmentApiEffects } from './crop-development-api.effects';
import { CropDevelopmentStateContainer } from './crop-development-state.model';
import { CropDevelopmentEffects } from './crop-development.effects';
import { cropDevelopmentReducer } from './crop-development.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(
            nameof<CropDevelopmentStateContainer>('cropDevelopment'),
            cropDevelopmentReducer,
        ),
        EffectsModule.forFeature([CropDevelopmentEffects, CropDevelopmentApiEffects]),
    ],
    providers: [CharacteristicsApiService],
})
export class CropDevelopmentStoreModule {}
