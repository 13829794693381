import { createAction, props } from '@ngrx/store';

import { DynamicComponent } from '@priva/components/dynamic';

import { AppError } from '../app.model';

export const initializeApp = createAction('[App] initialize app');

export const apiError = createAction('[App] Error', props<{ error: string | AppError }>());

export const logOut = createAction('[App] Log out');

export const openDialog = createAction('[App] Open dialog', props<{ dialog: DynamicComponent }>());
export const closeDialog = createAction('[App] Close dialog');

export const openPanel = createAction('[App] Open panel', props<{ panel: DynamicComponent }>());
export const closePanel = createAction('[App] Close panel');

export const resetState = createAction('[App] Reset state');

export const navigateToCropsAndReset = createAction('[App] Navigate to crops and reset state');

export const navigateToCrops = createAction('[App] Navigate to crops', props<{ url: string }>());
