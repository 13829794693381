import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpStatusCode,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, EMPTY, Observable, throwError } from 'rxjs';

import { PrivaErrorPageHandlerService } from '@priva/error-pages';

@Injectable()
export class ErrorHttpInterceptor implements HttpInterceptor {
    private readonly privaErrorPageHandlerService = inject(PrivaErrorPageHandlerService);

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                switch (error.status) {
                    case HttpStatusCode.Unauthorized:
                        // If URL includes '/bff/user', return and throw error (allow first 401 error coming form BFF)
                        if (!req.url.includes('/bff/user')) {
                            this.handleUnauthorized();
                            break;
                        }
                        return throwError(() => error);
                    case HttpStatusCode.Forbidden:
                        this.handleForbidden();
                        break;
                    case HttpStatusCode.InternalServerError:
                        this.handleInternalError(HttpStatusCode.InternalServerError);
                        break;
                    case HttpStatusCode.GatewayTimeout:
                        this.handleInternalError(HttpStatusCode.GatewayTimeout);
                        break;

                    default:
                        return throwError(() => error);
                }

                return EMPTY;
            }),
        );
    }

    private handleUnauthorized(): void {
        setTimeout(() => {
            // A reload makes sure to get a new token, if this will not work it will automatically redirect to the login page
            window.location.reload();
        }, 2000);
    }

    private handleForbidden(): void {
        this.privaErrorPageHandlerService.showErrorPageByHttpCode(HttpStatusCode.Forbidden);
    }

    private handleInternalError(statuscode: number): void {
        this.privaErrorPageHandlerService.showErrorPageByHttpCode(statuscode);
    }
}
