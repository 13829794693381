import { createReducer, on } from '@ngrx/store';

import { CropDevelopmentState, initialCropDevelopmentState } from './crop-development-state.model';
import { setCharacteristics } from './crop-development.actions';

export const cropDevelopmentReducer = createReducer(
    initialCropDevelopmentState,
    on(setCharacteristics, (state, action): CropDevelopmentState => {
        return {
            ...state,
            characteristicDefinitions: action.characteristicDefinitions,
        };
    }),
);
