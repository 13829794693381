import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ValidationItemClassPipe } from './validation-item-class.pipe';
import { ValidationItemIconPipe } from './validation-item-icon.pipe';
import { ValidationItem } from './validation-item.interface';

@Component({
    selector: 'app-validation-items',
    templateUrl: 'validation-items.component.html',
    standalone: true,
    imports: [NgClass, TranslateModule, ValidationItemIconPipe, ValidationItemClassPipe],
})
export class ValidationItemsComponent {
    @Input()
    public validationItems: ValidationItem[] = [];

    @Input()
    public translateParams: object | undefined;
}
