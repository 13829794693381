import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ValidationItemIconPipe } from '../validation-item/validation-item-icon.pipe';
import { ErrorDefinition } from './error-definition.interface';

@Component({
    selector: 'app-error-definition',
    templateUrl: 'error-definition.component.html',
    standalone: true,
    imports: [NgClass, TranslateModule, ValidationItemIconPipe],
})
export class ErrorDefinitionComponent {
    @Input()
    public errorDefinition: ErrorDefinition | undefined;
}
