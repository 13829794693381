import { createReducer, on } from '@ngrx/store';

import { loadCropsSuccess } from '@priva/masterdata';

import { loadCropsAndDefinitionsIfNeeded, resetTriggeredState } from './crops-page.actions';
import { CropsPageState, initialState } from './crops-page.state';

export const cropsPageReducer = createReducer(
    initialState,
    on(
        loadCropsAndDefinitionsIfNeeded,
        (state): CropsPageState => ({
            ...state,
            isLoadCropsAndDefinitionsTriggered: true,
        }),
    ),
    on(
        resetTriggeredState,
        (state): CropsPageState => ({
            ...state,
            isLoadCropsAndDefinitionsTriggered: false,
        }),
    ),
    on(
        loadCropsSuccess,
        (state): CropsPageState => ({
            ...state,
            isCropsLoaded: true,
        }),
    ),
);
