import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PrivaBffLoggedInUserGuard } from '@priva/auth/current-user';

import { AppMainComponent } from './app-main.component';
import { AppGuard } from './app.guard';

const routes: Routes = [
    {
        path: '',
        canActivate: [PrivaBffLoggedInUserGuard],
        children: [
            {
                path: 'error',
                loadChildren: () => import('@priva/error-pages').then((m) => m.PrivaErrorPagesModule),
            },
            {
                path: 'signout',
                loadChildren: () => import('@priva/auth/signout').then((m) => m.PrivaAuthSignoutModule),
            },
            {
                path: '',
                component: AppMainComponent,
                canActivate: [AppGuard],
                data: {
                    appGuard: {
                        redirectUrl: '/error/access-denied',
                    },
                },
                children: [
                    {
                        path: 'crops',
                        loadChildren: () => import('./pages/crops/crops-routes').then((r) => r.CROP_ROUTES),
                    },
                ],
            },
            { path: '**', redirectTo: '', pathMatch: 'full' },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [],
})
export class AppRoutingModule {}
