// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { version: appVersion, name: appName } = require('../../package.json');

export const environment = {
    production: true,
    api: '',
};

export const buildInfo = {
    version: appVersion,
    name: appName,
    buildDate: new Date(),
};
