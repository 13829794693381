@if (validationItems.length) {
    <div class="validation-form-control">
        @for (validationItem of validationItems; track validationItem) {
            <div class="validation-item" [ngClass]="validationItem.status | validationItemClass">
                <span class="status-icon" [ngClass]="validationItem.status | validationItemIcon"></span>
                <span class="validation-message">{{
                    validationItem.message | translate: translateParams
                }}</span>
            </div>
        }
    </div>
}
