// Angular packages
import { HttpClient } from '@angular/common/http';
// 3rd party packages
import { ActionReducer } from '@ngrx/store';

import { PrivaSegmentConfig } from '@priva/analytics/segment';
// @priva packages
import { AppShellConfiguration } from '@priva/appshell';
import { PrivaAuthHttpOptions } from '@priva/auth/http';
import { PrivaLocalizationOptions, VersionBasedTranslationLoader } from '@priva/localization';

import { AppConfigurationService } from '@app/utilities';

// Application packages
import { buildInfo } from '../environments/environment';

export function getCustomAuthOptions(): PrivaAuthHttpOptions {
    const options = new PrivaAuthHttpOptions();
    options.bffConfig = { csrfValue: undefined };
    return options;
}

export function getAppShellConfig(configService: AppConfigurationService): AppShellConfiguration {
    return AppShellConfiguration.from(configService.configuration);
}

export function getAnalyticsSegmentConfig(configService: AppConfigurationService): PrivaSegmentConfig {
    const analytics = configService.configuration.analytics;
    const segment = analytics ? analytics.segment : undefined;
    const apiKey = segment ? segment.apiKey : undefined;
    return new PrivaSegmentConfig(apiKey, {
        appName: configService.configuration.name,
        appLoadedEventName: 'Crop Performance Loaded',
        loadOnInitialization: apiKey !== undefined && apiKey !== '',
        trackInternalUsers: segment && !!segment.trackInternalUsers,
    });
}

export function getAppInsightsKey(configService: AppConfigurationService): {
    instrumentationKey: string | undefined;
} {
    return {
        instrumentationKey: configService.configuration.analytics.appInsights
            ? configService.configuration.analytics.appInsights.instrumentationKey
            : undefined,
    };
}

export function getCustomLocalizationOptions(): PrivaLocalizationOptions {
    const options = new PrivaLocalizationOptions();
    options.path = 'assets/translations';
    options.version = buildInfo.version;
    options.availableLanguagesResource = 'assets/translations/locales.json';
    options.defaultLanguage = 'en-US';
    return options;
}

export function getVersionBasedTranslationLoader(
    http: HttpClient,
    options: PrivaLocalizationOptions,
): VersionBasedTranslationLoader {
    return new VersionBasedTranslationLoader(http, options);
}

// eslint-disable-next-line
export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
    return (state, action) => {
        // console.log('state: ', state);
        // console.log('action: ', action);
        return reducer(state, action);
    };
}
