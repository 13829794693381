import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { Action, Store } from '@ngrx/store';
import { first, Observable } from 'rxjs';

import { AppShellAreaService, AppShellAreaType, AppShellUtilitiesModule } from '@priva/appshell';
import { PrivaAuthCurrentUserService } from '@priva/auth/current-user';
import { PrivaNotificationsModule, PrivaNotificationsService } from '@priva/components/notifications';
import { PrivaLocalizationService } from '@priva/localization';
import { PrivaToggleRouter } from '@priva/utilities/feature-toggles';
import { PrivaToggleModule } from '@priva/utilities/toggle';

import { AppError } from './app.model';
import { ErrorHandlerService } from './common/error-handling';
import { StatusErrorPageComponent } from './common/error-handling/status-error-page/status-error-page.component';
import { AppState } from './state/app.state';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    standalone: true,
    imports: [
        AppShellUtilitiesModule,
        PrivaToggleModule,
        PrivaNotificationsModule,
        RouterOutlet,
        StatusErrorPageComponent,
        AsyncPipe,
    ],
})
export class AppComponent {
    private store = inject(Store<{ app: AppState }>);
    private notificationService = inject(PrivaNotificationsService);
    private appShellAreaService = inject(AppShellAreaService);
    private errorHandler = inject(ErrorHandlerService);
    private localizationService = inject(PrivaLocalizationService);
    private toggleRouter = inject(PrivaToggleRouter);
    private userService = inject(PrivaAuthCurrentUserService);

    public AppShellAreaType = AppShellAreaType;

    public hasNotificationBar = false;
    public hasPrimaryNav = true;

    public error$: Observable<AppError | undefined>;

    constructor() {
        this.error$ = this.store.select((s) => s.app.error);

        // This is a temporary fix to make sure the empty app has languages
        this.localizationService.language.pipe(first()).subscribe();

        this.notificationService.barVisibility
            .pipe(takeUntilDestroyed())
            .subscribe((visible) => (this.hasNotificationBar = visible));

        // Has primary nav
        this.appShellAreaService
            .hasArea(AppShellAreaType.PrimaryNav)
            .pipe(takeUntilDestroyed())
            .subscribe((available: boolean) => (this.hasPrimaryNav = available));

        // Initialize ErrorHandler
        this.errorHandler.initialize();

        // overrideFeatures with user permissions
        this.userService.user
            .pipe(takeUntilDestroyed())
            .subscribe(({ features }) => this.toggleRouter.overrideFeatures(features));
    }

    public dispatchAction(action: Action) {
        this.store.dispatch(action);
    }
}
