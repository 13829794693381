import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import { filter, map, mergeMap, tap } from 'rxjs';

import { logOut, navigateToCrops, navigateToCropsAndReset, resetState } from './app.actions';

@Injectable({
    providedIn: 'root',
})
export class AppEffects {
    private actions$ = inject(Actions);
    private router = inject(Router);

    public logOut$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(logOut),
                tap(() => {
                    this.router.navigate(['signout']);
                }),
            );
        },
        { dispatch: false },
    );

    public routerNavigated$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            filter(
                (action: RouterNavigatedAction) =>
                    action.payload.event.url === '/' || action.payload.event.url.startsWith('/?'),
            ),
            map(() => {
                return navigateToCropsAndReset();
            }),
        );
    });

    public navigateToCropsAndReset$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(navigateToCropsAndReset),
            mergeMap(() => {
                return [resetState(), navigateToCrops({ url: '/crops' })];
            }),
        );
    });

    public navigateToCrops$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(navigateToCrops),
                tap((action) => {
                    this.router.navigate([action.url]);
                }),
            ),
        { dispatch: false },
    );
}
