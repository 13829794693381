import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, EMPTY, map, mergeMap } from 'rxjs';

import { CharacteristicsApiService } from '../characteristics.api.service';
import { CharacteristicsResponse } from '../crop-development-data.model';
import { loadCharacteristics, loadCharacteristicsSuccess } from './crop-development.actions';

@Injectable({
    providedIn: 'root',
})
export class CropDevelopmentApiEffects {
    private readonly _actions$ = inject(Actions);
    private readonly _characteristicsService = inject(CharacteristicsApiService);

    /**
     * This effect will really request the characteristics from the backend.
     * When received or when failed, the appropriate actions will be fired to deal with the result.
     */
    public loadCharacteristics$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadCharacteristics),
            mergeMap(() => {
                return this._characteristicsService.getCharacteristics().pipe(
                    map((response: CharacteristicsResponse) =>
                        loadCharacteristicsSuccess({
                            characteristicDefinitions: response.characteristicDefinitions,
                        }),
                    ),
                    catchError(() => EMPTY),
                );
            }),
        );
    });
}
