import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { ActionReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import {
    CropsStoreModule,
    CropsWithLocationsStoreModule,
    ExpandedCropsStoreModule,
    GreenhousesStoreModule,
    RowLocationsStoreModule,
    SitesStoreModule,
} from '@priva/masterdata';

import { AnalysisStoreModule } from '@app/analysis';
import { CropDevelopmentStoreModule } from '@app/crop-development-data';
import { KpiDeviationsStoreModule } from '@app/crops';
import { DefinitionsStoreModule } from '@app/definitions';
import { MonitoringStoreModule } from '@app/monitoring';
import { AppEffects } from 'app/state/app.effects';
import { appReducer } from 'app/state/app.reducer';

import { CropsPageStoreModule } from '../pages/crops/state/crops-page.store.module';

/* istanbul ignore next lvb: depends on environment */
export const devImports = [
    StoreDevtoolsModule.instrument({
        maxAge: 500,
        logOnly: false,
    }),
];

export function debug(reducer: ActionReducer<unknown>): ActionReducer<unknown> {
    return (state, action) => {
        // console.log('state: ', state);
        // console.log('action: ', action);
        return reducer(state, action);
    };
}

@NgModule({
    imports: [
        StoreModule.forRoot({ app: appReducer, router: routerReducer }),
        EffectsModule.forRoot([AppEffects]),
        AnalysisStoreModule,
        CropDevelopmentStoreModule,
        CropsStoreModule,
        DefinitionsStoreModule,
        KpiDeviationsStoreModule,
        MonitoringStoreModule,
        SitesStoreModule,
        GreenhousesStoreModule,
        ExpandedCropsStoreModule,
        RowLocationsStoreModule,
        CropsWithLocationsStoreModule,
        CropsPageStoreModule,

        ...devImports,
        StoreRouterConnectingModule.forRoot(),
    ],
})
export class AppStateModule {}
