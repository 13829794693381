import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfigurationService } from '@app/utilities';

import { CropDevelopmentDataApiService } from './crop-development-data.api.service';
import { CharacteristicsResponse } from './crop-development-data.model';

@Injectable({
    providedIn: 'root',
})
export class CharacteristicsApiService extends CropDevelopmentDataApiService {
    private readonly _appConfigurationService = inject(AppConfigurationService);

    private _baseUri: string = this._appConfigurationService.configuration.uris.characteristicsService;

    protected get baseUri(): string {
        return this._baseUri;
    }

    public getCharacteristics(): Observable<CharacteristicsResponse> {
        return this.get<CharacteristicsResponse>('', []);
    }
}
