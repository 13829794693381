import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';

import { AnalysisStoreModule } from '@app/analysis';
import { CropDevelopmentStoreModule } from '@app/crop-development-data';
import { MonitoringStoreModule } from '@app/monitoring';

import { DefinitionsEffects } from './definitions.effects';

@NgModule({
    imports: [
        AnalysisStoreModule,
        MonitoringStoreModule,
        CropDevelopmentStoreModule,
        EffectsModule.forFeature([DefinitionsEffects]),
    ],
    providers: [],
})
export class DefinitionsStoreModule {}
