import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { PrivaToggleRouter } from '@priva/utilities/feature-toggles';

type AppGuardData = {
    redirectUrl: any[];
};
/**
 * This routing guard is used to check if the current user has
 * the required permissions to access the application.
 */
@Injectable({
    providedIn: 'root',
})
export class AppGuard {
    constructor(
        private readonly router: Router,
        public toggleRouter: PrivaToggleRouter,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean | Promise<boolean> {
        const routeData = route.data.appGuard as AppGuardData;

        //@todo Make sure backend/mock-server sets this flag
        if (this.toggleRouter.isEnabled('priva.crop-performance.analysis')) return true;
        this.router.navigate([routeData.redirectUrl]);
        return false;
    }
}
