import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { PrimaryNavigationService, PrivaNavigationItem } from '@priva/appshell';

@Injectable()
export class AppPrimaryNavigationService implements PrimaryNavigationService {
    private navigation$: Observable<PrivaNavigationItem[]> = of([
        {
            label: 'APP.NAVIGATION.CROPS',
            label_short: 'APP.NAVIGATION.CROPS',
            slug: 'crops',
            url: '/crops',
            icon: 'far fa-seedling',
        },
    ]);

    public get navigation(): Observable<PrivaNavigationItem[]> {
        return this.navigation$;
    }
}
