import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PrivaButtonModule } from '@priva/components/button';
import { PrivaHeaderModule } from '@priva/components/header';
import { PrivaLocalizationModule, TranslateModule } from '@priva/localization';

import { ErrorDefinitionComponent } from './error-definition/error-definition.component';
import { ErrorHandlerService } from './error-handling.service';
import { StatusErrorPageComponent } from './status-error-page/status-error-page.component';
import { ValidationItemClassPipe, ValidationItemIconPipe, ValidationItemsComponent } from './validation-item';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        PrivaHeaderModule,
        PrivaButtonModule,
        PrivaLocalizationModule,
        ValidationItemIconPipe,
        ValidationItemClassPipe,
        ValidationItemsComponent,
        StatusErrorPageComponent,
        ErrorDefinitionComponent,
    ],
    providers: [ErrorHandlerService],
    exports: [StatusErrorPageComponent],
})
export class AppErrorHandlingModule {}
