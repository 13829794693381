import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    ApiErrorMessage,
    AppShellErrorService,
    AppShellErrorSeverity,
    AppShellErrorStatus,
    ErrorMessage,
} from '@priva/appshell';
import { PrivaNotificationsService } from '@priva/components/notifications';

@Injectable()
export class ErrorHandlerService {
    private readonly errorService = inject(AppShellErrorService);
    private readonly notificationsService = inject(PrivaNotificationsService);

    private apiMessages$: Observable<ApiErrorMessage>;
    private scriptMessages$: Observable<ErrorMessage>;

    constructor() {
        this.apiMessages$ = this.errorService.apiErrors;
        this.scriptMessages$ = this.errorService.scriptErrors;
    }

    public initialize() {
        this.apiMessages$.subscribe((message: ApiErrorMessage) => this.handleApiMessages(message));
        this.scriptMessages$.subscribe((message: ErrorMessage) => this.handleScriptMessages(message));
    }

    private handleApiMessages(error: ApiErrorMessage) {
        if (error.status === AppShellErrorStatus.Resolved) {
            this.notificationsService.close(error.uuid);
            return;
        }

        switch (error.severity) {
            case AppShellErrorSeverity.Error:
                this.notificationsService.bar.error(
                    `${error.messageLabel} reported status code ${error.statusCode}`,
                    error.uuid,
                );
                break;
            case AppShellErrorSeverity.Warning:
                this.notificationsService.bar.warning(error.messageLabel, error.uuid);
                break;
            default:
                break;
        }
    }

    private handleScriptMessages(message: ErrorMessage) {
        if (message.status === AppShellErrorStatus.Resolved) {
            this.notificationsService.close(message.uuid);
            return;
        }

        switch (message.severity) {
            case AppShellErrorSeverity.Error:
                this.notificationsService.bar.error(message.messageLabel, message.uuid);
                break;
            case AppShellErrorSeverity.Warning:
                this.notificationsService.bar.warning(message.messageLabel, message.uuid);
                break;
            default:
                break;
        }
    }
}
