import { createAction, props } from '@ngrx/store';

import { Characteristic } from '../crop-development-data.model';

export const loadCharacteristicsIfNeeded = createAction(
    '[CropDevelopment] Load characteristic definitions if needed',
);

export const loadCharacteristics = createAction('[CropDevelopment] Load characteristic definitions');

export const loadCharacteristicsSuccess = createAction(
    '[CropDevelopment] Load characteristics definitions success',
    props<{ characteristicDefinitions: Characteristic[] }>(),
);

export const setCharacteristics = createAction(
    '[CropDevelopment] Set characteristic definitions',
    props<{ characteristicDefinitions: Characteristic[] }>(),
);
