import { createSelector } from '@ngrx/store';

import { CropsPageState, CropsPageStateContainer } from './crops-page.state';

export const selectCropsPageState = (state: CropsPageStateContainer) => state.cropsPage;

export const selectIsLoadCropsAndDefinitionsTriggered = createSelector(
    selectCropsPageState,
    (state: CropsPageState) => state.isLoadCropsAndDefinitionsTriggered,
);

export const selectCropsLoaded = createSelector(
    selectCropsPageState,
    (state: CropsPageState) => state.isCropsLoaded,
);
