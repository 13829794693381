import { Colors } from '../charts/chart-series-configs';
import { CharacteristicsConfig } from './characteristics-config.interface';
import { CharacteristicsIds } from './characteristics.enum';

export const Yield: CharacteristicsConfig = {
    color: Colors['green1'],
    label: 'APP.REGISTRATIONS.WEEKLY_YIELD',
    testId: 'yield',
    id: CharacteristicsIds.Yield,
    decimals: 1,
    unit: 'APP.REGISTRATIONS.WEEKLY_YIELD_UNIT',
};

export const SetTruss: CharacteristicsConfig = {
    color: Colors['green1'],
    label: 'APP.REGISTRATIONS.WEEKLY_FRUIT_SET',
    testId: 'fruit-set',
    id: CharacteristicsIds.SetTruss,
    decimals: 1,
    unit: 'APP.REGISTRATIONS.WEEKLY_FRUIT_SET_UNIT',
};

export const RipeningTime: CharacteristicsConfig = {
    color: Colors['green1'],
    label: 'APP.REGISTRATIONS.RIPENING_TIME',
    testId: 'ripening-time',
    id: CharacteristicsIds.RipeningTime,
    decimals: 1,
    unit: 'APP.REGISTRATIONS.RIPENING_TIME_UNIT',
};
