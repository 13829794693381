import { Injectable } from '@angular/core';

import { HttpService } from '@app/utilities';

@Injectable({
    providedIn: 'root',
})
export abstract class CropDevelopmentDataApiService extends HttpService {
    private readonly _version: string = '1';

    protected get version(): string {
        return this._version;
    }
}
