import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';

import { loadMetricDefinitionsIfNeeded } from '@app/analysis';
import { loadCharacteristicsIfNeeded } from '@app/crop-development-data';
import { loadKpiDefinitionsIfNeeded } from '@app/monitoring';

import { loadDefinitionsIfNeeded } from './definitions.actions';

@Injectable({
    providedIn: 'root',
})
export class DefinitionsEffects {
    private readonly _actions$ = inject(Actions);

    /**
     * This effect just asks the needed definitions to make sure they are loaded.
     */
    public loadDefinitionsIfNeeded$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadDefinitionsIfNeeded),
            switchMap(() => {
                return of(
                    loadKpiDefinitionsIfNeeded(),
                    loadMetricDefinitionsIfNeeded(),
                    loadCharacteristicsIfNeeded(),
                );
            }),
        );
    });
}
