import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AnalysisStoreModule } from '@app/analysis';
import { CropDevelopmentStoreModule } from '@app/crop-development-data';
import { KpiDeviationsStoreModule } from '@app/crops';
import { MonitoringStoreModule } from '@app/monitoring';
import { nameof } from '@app/utilities';

import { CropsPageEffects } from './crops-page.effects';
import { cropsPageReducer } from './crops-page.reducer';
import { CropsPageStateContainer } from './crops-page.state';

@NgModule({
    imports: [
        StoreModule.forFeature(nameof<CropsPageStateContainer>('cropsPage'), cropsPageReducer),
        AnalysisStoreModule,
        MonitoringStoreModule,
        CropDevelopmentStoreModule,
        KpiDeviationsStoreModule,
        EffectsModule.forFeature([CropsPageEffects]),
    ],
    providers: [],
})
export class CropsPageStoreModule {}
