import { Characteristic } from '../crop-development-data.model';

export interface CropDevelopmentStateContainer {
    cropDevelopment: CropDevelopmentState;
}

export interface CropDevelopmentState {
    characteristicDefinitions: Characteristic[];
}

export const initialCropDevelopmentState: CropDevelopmentState = {
    characteristicDefinitions: undefined,
};
